/* eslint-disable no-undef */

(function() {
  function vendorModule() {
    'use strict';

    return self.Intl;
  }

  define('intl-polyfill', [], vendorModule);
})();
(function() {
  function vendorModule() {
    'use strict';

    return require('lodash').default;
  }

  define('lodash-charts', [], vendorModule);
})();
(function() {
  function vendorModule() {
    'use strict';

    return require('moment').default;
  }

  define('moment-charts', [], vendorModule);
})();

(function() {
  function vendorModule() {
    'use strict';

    return { default: self.P.noConflict() };
  }

  define('bluebird', [], vendorModule);
})();
(function() {
  function vendorModule() {
    'use strict';

    return { default: self.reqwest };
  }

  define('reqwest', [], vendorModule);
})();
